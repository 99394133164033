import * as React from "react"
import {
  DataTable,
  Hero,
  FeaturedDeals,
  // FeaturedOrganizations,
  FeaturedVps,
} from '@components';
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <DataTable />
    <FeaturedDeals
      stage="published"
      title="Invest in high-growth opportunities"
      text="Alongside expert venture partners and professional investors"
      label="Featured deals"
    />
    <FeaturedVps />
    <FeaturedDeals
      stage="settled"
      title="Success stories"
      text="Connect your company with our investment network"
      // label="Featured deals"
      hasBtn={false}
    />
  </Layout>
)

export default IndexPage
